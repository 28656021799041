import React from "react";
// import Fade from "react-reveal/Fade";
import bgImg from "../../images/Journey-bg.svg";
import journeyImg from "../../images/Journey.svg"

const Journey = ({ title, desc }) => {
  return (
    <div
      id="Journey"
      className="w-full h-auto my-10 text-5xl font-semibold text-center bg-no-repeat bg-cover text-primary-500 text-md"
      style={{
        backgroundImage: "url(" + bgImg + ")",
      }}
    >
      <div className="w-auto h-auto">
      رحـلــــة المــنتج
      </div>
      {/* <Fade bottom cascade> */}
        <div className="py-16 mt-10">
          <div className="m-auto w-max">
          <img src={journeyImg} alt="Product Journey" className="self-center w-[90vw] xl:ml-32 xl:w-[85vh]"/>
          </div>
          {/* <p
            id="journey txt"
            className="pt-16 text-xl text-center md:text-2xl font-extralight"
          >
            {desc}
          </p> */}
        </div>
      {/* </Fade> */}
    </div>
  );
};

export default Journey;
