import React from "react";
import logo from "../../images/logo-white-no-text.svg";

const ContactSection = ({ info, footer }) => {

  return (
    <div id="Contact" className="flex justify-center pt-24 min-h-max">
      <div
        id="Contact us"
        className="flex flex-col w-full border-gray-300 h-1/5 min-h-max min-w-min sm:flex-row "
                style={{
            backgroundImage:
              "linear-gradient(180deg, #733ac2 30%, 99% ,#f61067 150%) ",
          }}
        >
        <div
          id="contact information"
          className="w-full text-white sm:w-1/3"
        >
          <div className="flex items-center w-full h-full py-4">
          <img
            id="footer-logo"
            src = {logo}
            alt="Najla Logo"
            className="h-32 m-auto sm:h-56"
          />
           </div>
        </div>
        <div
          id="form"
          className="block w-full h-full pt-6 text-white scale-90 py-14 sm:w-2/5"
        >
          <h1 className="text-3xl font-semibold select-none px-7 md:text-4xl xl:text-5xl 2xl:text-6xl">
            {info.title}
          </h1>
          <p className="w-9/12 pt-8 pb-6 text-base font-light text-justify select-none px-7 sm:w-7/12 lg:w-11/12 lg:text-lg lg:pt-15 xl:text-xl 2xl:text-2xl lg:pb-10">
            {info.subTitle}
          </p>
          <div className="grid grid-cols-1 grid-rows-3 gap-4 mb-5 text-base font-light w-max lg:text-base xl:text-lg 2xl:text-xl sm:gap-6">
            <div className="flex flex-row leading-6 sm:leading-9">
              <img
                src={require("../../images/" + info.first.icon)}
                alt={info.first.alt}
                className="self-center h-6 px-4 flex-nowrap sm:h-9"
              />
              <a href={info.first.link} target="_blank" rel="noreferrer">
              {info.first.txt}
              </a>
            </div>
            <div className="flex flex-row leading-6 sm:leading-9">
              <img
                src={require("../../images/" + info.second.icon)}
                alt={info.second.alt}
                className="self-center h-6 px-4 flex-nowrap sm:h-9"
              />
              <a href={info.second.link} >
              {info.second.txt}
              </a>
            </div>
            <div className="flex flex-row leading-6 sm:leading-9">
              <img
                src={require("../../images/" + info.third.icon)}
                alt={info.third.alt}
                className="self-center h-6 px-4 flex-nowrap sm:h-9"
              />
              <a href={info.third.link} >
              {info.third.txt}
              </a>
            </div>
          </div>
          
        </div>
            <div id="footer" className="absolute bottom-0 flex flex-row justify-between w-full px-6 py-2 lg:px-12">
            <div className="self-center text-sm text-white md:text-lg">{footer.txt}</div>
            <div className="grid grid-cols-2 gap-4 grid-row-1 lg:gap-10">
              <a href={footer.first.link} target="_blank" rel="noreferrer">
                <img
                  id={footer.first.alt}
                  src={require("../../images/social-media-logos/" + footer.first.icon)}
                  alt={footer.first.alt}
                  className="h-7"
                />
              </a>
              {/* <a href={footer.second.link} target="_blank" rel="noreferrer">
                <img
                  id={footer.second.alt}
                  src={require("../../images/social-media-logos/" + footer.second.icon)}
                  alt={footer.second.alt}
                  className="h-7"
                />
              </a> */}
              <a href={footer.third.link} target="_blank" rel="noreferrer">
                <img
                  id={footer.third.alt}
                  src={require("../../images/social-media-logos/" + footer.third.icon)}
                  alt={footer.third.alt}
                  className="h-7"
                />
              </a>
            </div>
          </div>
      </div>
    </div>
  );
};

export default ContactSection;
