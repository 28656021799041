import React from "react";
// import Fade from "react-reveal/Fade";
import { Link as LinkScroll } from "react-scroll";

const HeroSection = ({ title, video, button }) => {
  return (
    <div
      id="Hero"
      className="relative flex justify-center select-none h-95sc "
    >
      <div className="absolute flex mt-8 shadow-2xl w-19/20 h-11/12 rounded-3xl">
        <img
          id="hero video"
          alt="hero"
          className="object-cover w-full h-full rounded-3xl"
          zindex="0"
          src={require("../../videos/" + video)}
        />
        <div
          className="absolute flex items-center w-full h-full shadow-inner rounded-3xl"
          style={{ background: "#733AC2d0", boxShadow: "inset 20px 20px 200px 200px rgb(0 0 0 / 0.3)" }}
        >
          <div
            id="HeroContent"
            className="relative block mx-auto h-max w-max max-w-max"
          >
            {/* <Fade bottom> */}
              <div>
                <h1 className="flex pb-10 text-5xl font-bold leading-tight text-center text-transparent bg-gradient-to-b from-slate-200 via-slate-200 to-green-400 bg-clip-text sm:text-5xl sm:leading-snug md:text-6xl md:leading-snug xl:leading-snug xl:text-8xl">
                  <text>{title}</text>
                </h1>

                <div className="flex flex-col justify-center w-full gap-8 sm:flex-row sm:gap-40 md:gap-60">
                  
                  <LinkScroll
                    id="[hero] Journey"
                    to="Journey"
                    smooth={true}
                    offset={-80}
                    className="w-32 text-xl m-auto font-normal leading-10 text-center text-white cursor-pointer h-10 rounded-xl bg-white bg-opacity-20 hover:bg-green-700 lg:w-48 lg:h-16 lg:leading-[4rem] lg:text-3xl"
                  >
                    كيف نعمل
                  </LinkScroll>
                  
                  <LinkScroll
                    id="[hero] contact us"
                    to="Contact"
                    smooth={true}
                    offset={15}
                    className="w-32 text-xl m-auto font-normal leading-10 text-center text-white cursor-pointer h-10 rounded-xl bg-gradient-to-r from-secondery-vibrant to-primary-vibrant hover:to-pink-500 hover:from-yellow-500 lg:w-48 lg:h-16 lg:leading-[4rem] lg:text-3xl"
                  >
                    {button}
                  </LinkScroll>
                  
                </div>
              </div>
            {/* </Fade> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
