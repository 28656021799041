export const navBar = {
  item1: "عننا",
  item2: "قصتنا",
  item3: "رحلة المنتج",
  item4: "المعرض",
  button: "تواصل معنا",
};

export const heroSection = {
  title: (
    <text>
      {" "}
      مصنع نجلاء <br /> لتعبئة وتغليف المواد الغذائية{" "}
    </text>
  ),
  video: "pexels-karolina-grabowska-4226798 (1).jpg",
  button: "تواصل معنا",
};

export const infoSection = {
  about: {
    title: "مـــن نحـــــــن",
    desc: "يقع مصنع نجلاء في مدينة سدير الصناعية، و يخضع لاشتراطات هيئة المدن الصناعية الخاصة بالجودة و السلامة. جميع المعدات و الكبسولات مطابقة للمواصفات السعودية الصادرة من هيئة المواصفات و المقاييس. كما طور مصنع نجلاء إجراءات جودة و سلامة مفصلة",
  },
  areas: {
    // To change any icon, place the new icon in 'src\images\Areas' then write the new icon's name with extension exactly as is in the 'icon' property
    // And change the alt property to any text that descripes the new image
    // any other property can be changed to any text
    /* For Example:
                  Areas4: {
                      icon: "camera.svg",
                      alt:"Camera",
                      title: "Security Systems",
                      desc: "We have a great experince in installing managing and maintaining security system including CCTV, Access Control",
            }, */

    MainTitle: "قصتنـــــــــا",
    Area1: {
      img: "eye.svg",
      alt: "eye",
      title: "الرؤية",
      desc: "تمكين صناعة منتجات القهوة الوطنية من خلال حلول صناعية مبتكرة",
    },
    Area2: {
      img: "mail.svg",
      alt: "message",
      title: "الرسالة",
      desc: "التميز والجودة والابتكار في تعبئة وتغليف المواد الغذائية",
    },
    Area3: {
      img: "flag.svg",
      alt: "goal",
      title: "الهدف",
      desc: "توسيع سوق القهوة بتقديم منتجات تغليف بجودة عالية تناسب شركائنا وتساعدهم على لاكتساب عملاء جدد",
    },
    Area4: {
      img: "camera.svg",
      alt: "Camera",
      title: "انظمة الحماية",
      desc: "يضمن تصميمنا المرن التكامل السريع للتحكم في الوصول والوقت والحضور والتحكم في المصعد والمراقبة بالفيديو عبر منصة مشتركة.",
    },
  },
  solutions: {
    // To change any image, place the new image in 'src\images\solutions' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
                  image1: {
                      imgName: "server-room.png",
                      alt:"server room"
            }, */
    title: "الـــــــمعرض",
    image1: { imgName: "1.jpeg", alt: "amc lobby" },
    image2: { imgName: "2.jpeg", alt: "amc screens" },
    image3: { imgName: "3.jpeg", alt: "network switch" },
    image4: { imgName: "4.jpeg", alt: "man on laptop" },
    image5: { imgName: "1.jpeg", alt: "lobby" },
    image6: { imgName: "1.jpeg", alt: "teaching" },
    image7: { imgName: "1.jpeg", alt: "cameras" },
    image8: { imgName: "1.jpeg", alt: "lobby" },
  },
  clients: {
    // To change any image, place the new image in 'src\images' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
                  Client1: {
                      imgName: "Moda.png",
                      alt:"Ministry of Defense"
            }, */
    title: "فخورين بالعمل مع",
    Client1: {
      img: "moda.svg",
      alt: "Ministry of Defense",
    },
    Client2: {
      img: "moi.svg",
      alt: "Ministry of Interior",
    },
    Client3: {
      img: "moe.svg",
      alt: "Ministry of Education",
    },
    Client4: {
      img: "moh.svg",
      alt: "Minisrty of Health",
    },
    Client5: {
      img: "rcrc.png",
      alt: "Royal Commission for Riyadh City",
    },
    Client6: {
      img: "kacst.svg",
      alt: "King Abdulaziz City for Science and Technology",
    },
    Client7: {
      img: "amc.png",
      alt: "AMC Cinemas",
    },
    Client8: {
      img: "Taqnia.png",
      alt: "Taqnia",
    },
  },
};

export const contactSection = {
  info: {
    title: (
      <text>
        {" "}
        معلومات <br /> التواصـــــــل.{" "}
      </text>
    ),
    subTitle: "نسعد بالسماع منك لأي اسئلة او استفسارات.",
    first: {
      icon: "marker.svg",
      alt: "location",
      txt: "مدينة سدير للصناعة والأعمال 22345، المملكة العربية السعودية",
      link: "https://goo.gl/maps/ZXSTLrzKopyva28fA"
    },
    second: {
      icon: "phone.svg",
      alt: "phone",
      txt: "3105 043 53 966+",
      link: "tel:+966530433105"
    },
    third: {
      icon: "email.svg",
      alt: "email",
      txt: "Info@najlafp.com",
      link: "mailto:Info@najlafp.com"
    },
  },
  form: {
    title: "ارسل لنـــــا",
    alertMessage:{
      success : "شكراً لتواصلك معنا",
      error : "نرجوا اكمال التحقق اولاً",
      fail : "نأسف، حدث خطأ غير متوقع"
    },
    input1: {
      label: "الاسم:",
      placeholder: "محمد عبدالله",
    },
    input2: {
      label: "الايميل:",
      placeholder: "name@company.com",
    },
    input3: {
      label: "رقم الجوال:",
      placeholder: "500000000",
    },
    input4: {
      label: "الشركة:",
      placeholder: "الشركة القابضة",
    },
    input5: {
      label: "الرسالة:",
    },
    button: "ارسل",
  },
  footer: {
    txt: "© 2023 مصنع نجلاء لتعبئة وتغليف المواد الغذائية.",
  first: {
    icon: "twitter.svg",
    alt: "twitter",
    link: "https://twitter.com/Najla_FP",
  },
  second: {
    icon: "facebook.svg",
    alt: "facebook",
    link: "https://www.facebook.com/",
  },
  third: {
    icon: "linkedin.svg",
    alt: "linkedin",
    link: "https://www.linkedin.com/in/najla-food-packaging-80b725264",
  },
  }
};

export const footer = {
  txt: "© 2021 Seven Layers IT Solutions.",
  first: {
    icon: "twitter.svg",
    alt: "twitter",
    link: "https://twitter.com/Najla_FP/",
  },
  second: {
    icon: "facebook.svg",
    alt: "facebook",
    link: "https://www.facebook.com/",
  },
  third: {
    icon: "linkedin.svg",
    alt: "linkedin",
    link: "https://www.linkedin.com/in/najla-food-packaging-80b725264",
  },
};
