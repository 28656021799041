import React from "react";

function Card({ title, desc, icon, alt }) {
  console.log("../../images/Areas/"+icon)
  return (
    <div
      id={title + " card"}
      className="flex flex-col content-center justify-center text-center duration-500 transform hover:scale-110 w-72 "
    >
      <div className="flex self-center justify-center w-24 h-24 mb-6 rounded-full select-none bg-gradient-to-br from-purple-500 to-primary-500">
        <img
          src={require("../../images/Areas/"+icon)}
          alt={alt}
          className="w-2/3"
          fill="white"
          color="#ffffff"
        />
      </div>
      <h1 className="pb-4 overflow-hidden text-2xl text-primary-700 lg:text-3xl">
        {title}
      </h1>
      <p className="text-base font-medium text-primary-300 lg:text-md">{desc}</p>
    </div>
  );
}

const Areas = ({ Area1, Area2, Area3, Area4, MainTitle }) => {
  console.log(Area4.img)
  return (
    <div id="Areas" className="flex flex-col items-center py-24">
      <h1
        id="areas title"
        className="flex flex-col justify-center text-5xl font-semibold text-center select-none text-primary-600 text-md"
      >
        {MainTitle}
      </h1>

      <div
        id="areas grid"
        className="grid w-11/12 grid-rows-3 gap-16 py-20 justify-items-center justify-self-center md:grid-cols-2 md:grid-rows-2 xl:grid-cols-3 xl:grid-rows-1 "
      >
        {/* <Slide up> */}
          {/* ----------------------------------------------------------------------------------- */}
          <Card
            title={Area1.title}
            icon={Area1.img}
            alt={Area1.alt}
            desc={Area1.desc}
          />
          {/* ----------------------------------------------------------------------------------- */}
          <Card
            title={Area2.title}
            icon={Area2.img}
            alt={Area2.alt}
            desc={Area2.desc}
          />
        {/* </Slide> */}
        {/* ----------------------------------------------------------------------------------- */}
        {/* <Slide right> */}
          <Card
            title={Area3.title}
            icon={Area3.img}
            alt={Area3.alt}
            desc={Area3.desc}
          />
          {/* ----------------------------------------------------------------------------------- */}
          {/* <Card
            title={Area4.title}
            icon={Area4.img}
            alt={Area4.alt}
            desc={Area4.desc}
          /> */}
        {/* </Slide> */}
        {/* ----------------------------------------------------------------------------------- */}
      </div>
    </div>
  );
};

export default Areas;
