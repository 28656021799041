import React from "react";
import { animateScroll, Link as LinkScroll } from "react-scroll";
import logo from "../../images/Logo-with-text-purple.svg";
import menu from "../../images/menu.svg";
// import Bounce from "react-reveal/Bounce";
import mobilelogo from "../../images/logo-purple-no-text.svg";
import { NavLinks } from "./NavbarElements";

const scrollToHero = () => {
  animateScroll.scrollToTop({ duration: 1200, smooth: "easeInOutQuint" });
};

const Navbar = ({ toggle, item1, item2, item3, item4, button, AR }) => {
  return (
    <>
      <nav
        id="Navbar"
        className="sticky top-0 z-10 flex items-center justify-between h-16 px-8 overflow-hidden select-none bg-[#EFEFEF] md:h-18"
      >
        {/* <Bounce left> */}
          <div>
            <div
              id="logoWraper"
              className="flex self-center flex-none h-16 py-1 cursor-pointer md:h-18"
              onClick={scrollToHero}
            >
              <img className="hidden lg:block" src={logo} alt="7-Layers Logo" />
              <img className="lg:hidden" src={mobilelogo} alt="7-Layers Logo" />
            </div>
          </div>
        {/* </Bounce> */}

        <ul className="relative items-center justify-center flex-grow hidden text-center list-none md:flex">
          <li className="h-20">
            <NavLinks
              id="about"
              to="About"
              smooth={true}
              spy={true}
              offset={-80}
            >
              {item1}
            </NavLinks>
          </li>
          <li className="h-20">
            <NavLinks id="areas" to="Areas" smooth={true} spy={true}>
              {item2}
            </NavLinks>
          </li>
          <li className="h-20">
            <NavLinks id="journey" to="Journey" smooth={true} spy={true} offset={-90}>
              {item3}
            </NavLinks>
          </li>
          <li className="h-20">
            <NavLinks id="solutions" to="Solutions" smooth={true} spy={true}>
              {item4}
            </NavLinks>
          </li>
        </ul>

        {/* {AR ? (
          <a
            id="change to english"
            href="/"
            className="flex-grow-0 hidden pl-8 text-xl font-normal text-center text-primary-600 font-poppins md:flex"
          >
            English
          </a>
        ) : (
          <a
            id="change to arabic"
            href="/ar"
            className="flex-grow-0 hidden pr-8 text-xl font-medium text-center text-primary-600 font-almarai md:flex"
          >
            العربية
          </a>
        )} */}
        {/* <Bounce right> */}
          <div>
            <div className="justify-end flex-grow-0 hidden w-32 justify-self-end md:flex ">
              <LinkScroll
                id="Contact US"
                to="Contact"
                smooth={true}
                offset={15}
                className="w-32 h-10 text-lg font-normal leading-10 text-center text-white rounded-full cursor-pointer justify-self-end bg-gradient-to-r from-secondery-vibrant to-primary-vibrant hover:to-pink-500 hover:from-yellow-500 "
              >
                {button}
              </LinkScroll>
            </div>
          </div>
        {/* </Bounce> */}

        <div id="sidebar open" className="flex md:hidden" onClick={toggle}>
          <img src={menu} alt="menu-icon" />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
