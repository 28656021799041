import React from "react";
import Areas from "./Areas";
// import Clients from "./Clients";
import About from "./About";
import Solutions from "./Solutions";
import Journey from "./Journey";

const InfoSection = ({ about, areas, solutions, clients }) => {
  return (
    <>
      <About {...about} />
      <Areas {...areas} />
      <Journey/>
      <Solutions {...solutions} />
      {/* <Clients {...clients} /> */}
    </>
  );
};

export default InfoSection;
