import React from "react";
// import Fade from "react-reveal/Fade";
import { useState } from "react";

const Card = ({ imgname, alt, toggle }) => {
  return (
    <div className="cursor-pointer ">
      <img
        id={imgname}
        className="object-cover w-full h-64 rounded-lg cursor-pointer"
        src={require("../../images/Solutions/" + imgname)}
        alt={alt}
        onClick={() => {
          toggle(imgname);
        }}
      />
    </div>
  );
};

const Solutions = ({
  title,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
}) => {
  const [backDrop, setbackDrop] = useState(false);
  const [image, setimage] = useState(image1.imgName);
  const toggle = (img) => {
    setbackDrop(!backDrop);
    setimage(img);
  };

  return (
    <div
      id="Solutions"
      className="flex flex-col items-center justify-center pt-24 select-none "
    >
      <h1 className="flex flex-col justify-center px-4 pb-10 text-5xl font-semibold text-center select-none text-primary-600 text-md">
        {title}
      </h1>
      <div className="grid items-center object-right w-10/12 grid-cols-2 gap-x-10 gap-y-10 sm:w-7/12 md:w-6/12 xl:w-7/12 lg:w-11/12 lg:px-0 lg:grid-cols-4 ">
        {/* <Fade left> */}
          <Card imgname={image1.imgName} alt={image1.alt} toggle={toggle} />
          <Card imgname={image2.imgName} alt={image2.alt} toggle={toggle} />
          <Card imgname={image3.imgName} alt={image3.alt} toggle={toggle} />
          <Card imgname={image4.imgName} alt={image4.alt} toggle={toggle} />
        {/* </Fade> */}
        {/* <Fade right> */}
          {/* <Card imgname={image5.imgName} alt={image5.alt} toggle={toggle} />
          <Card imgname={image6.imgName} alt={image6.alt} toggle={toggle} />
          <Card imgname={image7.imgName} alt={image7.alt} toggle={toggle} />
          <Card imgname={image8.imgName} alt={image8.alt} toggle={toggle} /> */}
        {/* </Fade> */}
      </div>
      <div
        className={` fixed top-0 h-screen w-screen bg-black bg-opacity-70 justify-center items-center z-40 transition-opacity duration-1000
       ${backDrop ? "flex" : "hidden"} `}
        onClick={() => toggle(image)}
      >
        <img
          className="z-50 h-3/6"
          src={require("../../images/Solutions/" + image)}
          alt="dd"
        />
      </div>
    </div>
  );
};

export default Solutions;
