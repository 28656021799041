import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react";
import TagManager from "react-gtm-module";
import Home from "./pages";
// import Arabic from "./pages/arabic";

const tagManagerArgs = {
  gtmId: "GTM-5JZXR6L",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home/>}  />
          {/* <Route path="/ar" element={<Arabic/>}  /> */}
          <Route  path="*"
        element={<Navigate to="/" replace />}/>
      </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
