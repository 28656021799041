import React, { useState } from "react";
import ContactSection from "../components/ContactSection/Index";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection/Index";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import {
  contactSection,
  heroSection,
  infoSection,
  navBar,
} from "../Data-Arabic";

const Home = () => {

  const [isOpen, setIsOpen] = useState(false);

  document.title = "مصنع نجلاء لتعبئة وتغليف المواد الغذائية"

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ fontFamily: "ubuntu", direction: "rtl", backgroundColor:"#EFEFEF" }}>
      <Sidebar {...navBar} isOpen={isOpen} toggle={toggle} AR={true} />
      <Navbar {...navBar} toggle={toggle} AR={true} />
      <HeroSection {...heroSection} />
      <InfoSection {...infoSection} />
      <ContactSection {...contactSection} AR={true} />
      {/* <Footer {...footer} /> */}
    </div>
  );
};

export default Home;
